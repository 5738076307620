<template>
  <div class="footers">
    <b-container class="bv-example-row" fluid>
      <b-row cols="1" cols-sm="1" cols-md="1" cols-lg="1">
        <b-col style="text-align: center"
          ><b-card style="border: 0; border-radius: 0; background-color: #222">
            <b-card-text>
              Copyright © 2021 小鲲智能技术(广州)有限公司 版权所有<br />
              <a style="color:#555555" target="_blank" href="https://beian.miit.gov.cn">粤ICP备2021056939号</a>
            </b-card-text>
          </b-card></b-col
        >
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.footers {
  width: 100%;
  background-color: #222;
  .card-body {
    padding: 20px 5px;
  }
  .card-text {
    color: #555555;
    font-size: 12px;
  }
}
</style>