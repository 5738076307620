<template>
  <div class="home">
    <div class="home_nav">
      <div class="nav_box">
        <b-navbar class="xk_navbar" fixed="top" toggleable="lg">
          <b-navbar-brand href="#" style="padding: 0; margin: 0 0 0 20px">
            <b-img
              :src="require('../assets/xk_logo.png')"
              fluid
              alt="小鲲智能"
            ></b-img>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse">
            <template #default="{ expanded }">
              <b-icon v-if="expanded" icon="justify"></b-icon>
              <b-icon v-else icon="justify"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item to="/home">首页</b-nav-item>
              <b-nav-item to="/about">关于小鲲</b-nav-item>

              <!-- <b-nav-item to="/product">解决方案</b-nav-item> -->
              <b-nav-item-dropdown text="解决方案">
                <b-dropdown-item to="/maintenanceCase"
                  >设备运维管理系统</b-dropdown-item
                >
                <b-dropdown-item to="/dosingCase"
                  >精准加药控制系统</b-dropdown-item
                >
                <!-- <b-dropdown-item to="/aeration"
                  >精准曝气控制系统</b-dropdown-item
                > -->
                <b-dropdown-item to="/plc">智能物联PLC</b-dropdown-item>
              </b-nav-item-dropdown>
              <!-- <b-nav-item to="/case">应用案例</b-nav-item> -->
              <b-nav-item href="http://124.71.58.35:8888/xiaokun/#/" target="_blank"
                >平台入口</b-nav-item
              >
              <b-nav-item to="/contact">联系我们</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <div class="content">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/footers.vue";
export default {
  name: "Home",
  data() {
    return {
      bgtoggle: true,
    };
  },
  components: {
    Footer,
  },
};
</script>
<style lang="less" scoped>
.home_nav {
  width: 100%;
  height: 100%;
  overflow: scroll;
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  // justify-content: center;
  .ml-auto {
    margin-right: 100px;
  }
  .nav-item {
    margin-right: 20px;
  }
  /deep/.nav-link {
    color: #000;
  }
  /deep/.dropdown-menu {
    background-color: rgba(255, 255, 255, 0.8);
    border: 0;
  }
  /deep/.router-link-exact-active,
  /deep/.router-link-active {
    color: #32d4f8 !important;
  }
  .nav_box{
    position: relative;
  }
  .xk_navbar {
    padding: 0.75rem 1rem;
    background: rgba(255, 255, 255, 0.9);
  }
}
.content {
  width: 100%;
  height: auto;
  margin-top: 64px;
}
@media screen and(max-width:992px) {
  /deep/.dropdown-menu {
    background-color: transparent !important;
  }
}
.slide-fade-leave-active,
.slide-fade-enter-active {
  transition: all 0.5s;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
